.isophi-box {
  position: relative;
  background: white;
  margin-top: 30px;
  border-radius: 10px;
  padding: 20px;

  @media screen and (max-width: 1360px) {
    margin: 30px 30px 0;
  }

  @media print {
    border-color: transparent;
  }
}

// Headers with arrows

.arrow-right,
.arrow-down {
  color: #ebebeb;
  background: #464a53;
  position: relative;

  &::before {
    display: block;
    content: '';
    position: absolute;
  }
}

.arrow-right {
  display: flex;
  align-content: center;
  height: 2.4em;
  padding: 10px 15px;
  width: 12em;
  font-size: 1em;
  font-weight: normal;

  &::before {
    top: 0;
    right: -14.7px;
    border-left: 15px solid #464a53;
    border-top: 1.2em solid transparent;
    border-bottom: 1.2em solid transparent;
  }
}

.arrow-down {
  padding: 15px;
  font-weight: 400;
  text-align: center;
  font-size: 1.2em;

  &::before {
    bottom: -14px;
    left: calc(50% - 40px);
    border-top: 15px solid #464a53;
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
  }
}

// Expansion panel

.expansion-panel {
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 0 15px 1px;
  background: white;

  .expansion-panel-header {
    padding: 15px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .fa-angle-down {
    margin-left: auto;
    font-size: 1.8em;

    &.rotate {
      transform: rotate(180deg);
    }
  }
}

// Date picker form input

.fa-calendar {
  font-size: 2.5em;
}

.date-input {
  border-radius: 0.45rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

// Modal dialog

// .modal-dialog {
//   max-width: 70% !important;
// }

.modal-header {
  align-items: center;

  button {
    border: none;
    background: none;

    img {
      height: 2rem;
    }
  }
}

.modal-footer-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .modal-footer-right {
    margin-left: auto;
    flex-shrink: 0;

    @media (max-width: 768px) {
      width: 100%;
      margin-left: 0;
    }
  }
}
