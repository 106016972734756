/*-------- Buttons ------*/

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.84615385;
  font-size: 14px;
  padding: 0.299rem 0.75rem;
  letter-spacing: 0.4px;
  border-radius: 5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none;
  }
  &.focus {
    outline: 0;
    box-shadow: none;
  }
  &.disabled,
  &:disabled {
    opacity: 0.65;
  }
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
    &:active,
    &.active {
      background-image: none;
    }
  }
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: $white !important;
  background-color: $primary;
  border-color: $primary;
  &:hover {
    color: $white !important;
    background-color: $primary;
    border-color: #9ece11;
    opacity: 0.9;
  }
  &:focus,
  &.focus {
    color: $white !important;
    background-color: $primary;
    border-color: $primary;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $primary-hover;
    border-color: $primary-hover;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $primary-hover;
      border-color: $primary-hover;
    }
  }
}
.show > .btn-primary.dropdown-toggle {
  color: $white;
  background-color: $primary-hover;
  border-color: $primary-hover;
}
.btn-primary:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.1);
  }
}
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.1);
}
.btn-secondary {
  background-color: rgba(255, 255, 255, 0);
  border-color: #9ece11;
  box-shadow: 0 0px 10px -5px rgba(254, 127, 0, 0.3);
  &:hover {
    background-color: #9ece11;
    border-color: #9ece11;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(254, 127, 0, 0.3);
    background-color: #9ece11;
    border-color: #9ece11;
  }
  &.disabled,
  &:disabled {
    border-color: #9ece11;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      border-color: #9ece11;
    }
  }
}
.show > .btn-secondary.dropdown-toggle {
  color: $white;
  background-color: $secondary;
  border-color: $secondary;
}
.btn-secondary:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(254, 127, 0, 0.1);
  }
}
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(254, 127, 0, 0.1);
}
.btn-success {
  color: $white !important;
  background-color: $success;
  border-color: #09bf89;
  box-shadow: 0 0px 10px -5px rgba(45, 206, 137, 0.5);
  &:hover {
    color: $white !important;
    background-color: #09bf89 !important;
    border-color: #09bf89 !important;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(9, 191, 137, 0.1);
    color: $white !important;
    background-color: $success;
    border-color: $success;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $success;
    border-color: $success;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $success;
      border-color: $success;
    }
  }
}
.show > .btn-success.dropdown-toggle {
  color: $white;
  background-color: $success;
  border-color: $success;
}
.btn-success:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(9, 191, 137, 0.1);
  }
}
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(9, 191, 137, 0.1);
}
.btn-info {
  color: $white !important;
  background-color: $info;
  border-color: #0c85f5;
  box-shadow: 0 0px 10px -5px rgba(12, 133, 245, 0.5);
  &:hover {
    color: $white !important;
    background-color: #0c85f5;
    border-color: #0c85f5;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(12, 133, 245, 0.1);
    color: $white !important;
    background-color: $info;
    border-color: $info;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #0c85f5;
    border-color: #0c85f5;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #0c85f5;
      border-color: #0c85f5;
    }
  }
}
.show > .btn-info.dropdown-toggle {
  color: $white;
  background-color: #0c85f5;
  border-color: #0c85f5;
}
.btn-info:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(12, 133, 245, 0.1);
  }
}
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(12, 133, 245, 0.1);
}
.btn-warning {
  color: $white !important;
  background-color: $warning;
  border-color: #efbc1b;
  box-shadow: 0 0px 10px -5px rgba(247, 191, 13, 0.5);
  &:hover {
    color: $white !important;
    background-color: #f7bf0d;
    border-color: #f7bf0d;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
    color: $white !important;
    background-color: $warning;
    border-color: $warning;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #f7bf0d;
    border-color: #f7bf0d;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #f7bf0d;
      border-color: #f7bf0d;
    }
  }
}
.show > .btn-warning.dropdown-toggle {
  color: $white;
  background-color: #f7bf0d;
  border-color: #f7bf0d;
}
.btn-warning:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
  }
}
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
}
.btn-danger {
  color: $white !important;
  background-color: $danger;
  border-color: $danger;
  box-shadow: 0 0px 10px -5px rgba(249, 26, 63, 0.44);
  &:hover {
    color: $white !important;
    background-color: #f91a3f;
    border-color: #f91a3f;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(250, 98, 107, 0.1);
    color: $white !important;
    background-color: $danger;
    border-color: $danger;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #f91a3f;
    border-color: #f91a3f;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $danger;
      border-color: $danger;
    }
  }
}
.show > .btn-danger.dropdown-toggle {
  color: $white;
  background-color: $danger;
  border-color: $danger;
}
.btn-danger:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(250, 98, 107, 0.1);
  }
}
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(250, 98, 107, 0.1);
}
.btn-light {
  color: #565b95;
  background-color: #dee5f7;
  border-color: #dee5f7;
  box-shadow: 0 0 10px -5px rgba(68, 84, 195, 0.2);
  &:hover {
    color: #565b95;
    background-color: #dee3f3;
    border-color: #dee3f3;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
    color: #565b95;
    background-color: #dee3f3;
    border-color: #dee3f3;
  }
  &.disabled,
  &:disabled {
    background-color: #dee3f3;
    border-color: #dee3f3;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #565b95;
      background-color: #dee3f3;
      border-color: #dee3f3;
    }
  }
}
.show > .btn-light.dropdown-toggle {
  color: #495057;
  background-color: #dee3f3;
  border-color: #dee3f3;
}
.btn-light:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
  }
}
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}
.btn-default {
  color: #495057;
  background-color: #eceef7;
  border-color: #dae0e5;
  box-shadow: 0 0 10px -5px rgba(68, 84, 195, 0.2);
  &:hover {
    color: #495057;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
    color: #495057;
    background-color: #eceef7;
    border-color: #dae0e5;
  }
  &.disabled,
  &:disabled {
    background-color: #eceef7;
    border-color: #eceef7;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #495057;
      background-color: #dae0e5;
      border-color: #d3d9df;
    }
  }
}
.show > .btn-default.dropdown-toggle {
  color: #495057;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-default:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
  }
}
.show > .btn-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}
.btn-dark {
  color: $white;
  background-color: $dark;
  border-color: $dark;
  &:hover {
    color: $white;
    background-color: #23272b;
    border-color: #1d2124;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
    color: $white;
    background-color: #23272b;
    border-color: #1d2124;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $dark;
    border-color: $dark;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #1d2124;
      border-color: #171a1d;
    }
  }
}
.show > .btn-dark.dropdown-toggle {
  color: $white;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(217, 221, 231, 0.1);
  }
}
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(217, 221, 231, 0.1);
}
.btn-outline-primary {
  color: $primary;
  background-color: transparent;
  background-image: none;
  border-color: $primary;
  box-shadow: 0 0px 10px -5px rgba(68, 84, 195, 0.5);
  &:hover {
    color: $white !important;
    background-color: #9acd17;
    border-color: #9acd17;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.1);
  }
  &.disabled,
  &:disabled {
    color: #9acd17;
    background-color: transparent;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #9acd17;
      border-color: #9acd17;
    }
  }
}
.show > .btn-outline-primary.dropdown-toggle {
  color: $white !important;
  background-color: $primary;
  border-color: $primary;
}
.btn-outline-primary:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
  }
}
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
}
.btn-outline-secondary {
  color: $secondary !important;
  background-color: transparent;
  background-image: none;
  border-color: $secondary;
  box-shadow: 0 0px 10px -5px rgba(242, 46, 105, 0.5);
  &:hover {
    color: $white !important;
    background-color: $secondary;
    border-color: $secondary;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
  }
  &.disabled,
  &:disabled {
    color: $secondary;
    background-color: transparent;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $secondary;
      border-color: $secondary;
    }
  }
}
.show > .btn-outline-secondary.dropdown-toggle {
  color: $white !important;
  background-color: $secondary;
  border-color: $secondary;
}
.btn-outline-secondary:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
  }
}
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(247, 45, 102, 0.1);
}
.btn-outline-success {
  color: $success !important;
  background-color: transparent;
  background-image: none;
  border-color: $success;
  box-shadow: 0 0px 10px -5px rgba(45, 206, 137, 0.5);
  &:hover {
    color: $white !important;
    background-color: $success;
    border-color: $success;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(9, 191, 137, 0.1);
  }
  &.disabled,
  &:disabled {
    color: $success;
    background-color: transparent;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white !important;
      background-color: $success;
      border-color: $success;
    }
  }
}
.show > .btn-outline-success.dropdown-toggle {
  color: $white !important;
  background-color: $success;
  border-color: $success;
}
.btn-outline-success:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(9, 191, 137, 0.1);
  }
}
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(9, 191, 137, 0.1);
}
.btn-outline-orange {
  color: $orange !important;
  background-color: transparent;
  background-image: none;
  border-color: $orange;
  box-shadow: 0 0px 10px -5px rgba(240, 74, 32, 0.2);
  &:hover {
    color: $white !important;
    background-color: $orange;
    border-color: $orange;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(240, 74, 32, 0.1);
  }
  &.disabled,
  &:disabled {
    color: $orange;
    background-color: transparent;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white !important;
      background-color: $orange;
      border-color: $orange;
    }
  }
}
.show > .btn-outline-orange.dropdown-toggle {
  color: $white !important;
  background-color: $orange;
  border-color: $orange;
}
.btn-outline-orange:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(240, 74, 32, 0.1);
  }
}
.show > .btn-outline-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(240, 74, 32, 0.1);
}
.btn-outline-info {
  color: $info !important;
  background-color: transparent;
  background-image: none;
  border-color: $info;
  box-shadow: 0 0px 10px -5px rgba(69, 170, 242, 0.5);
  &:hover {
    color: $white !important;
    background-color: $info;
    border-color: $info;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.1);
  }
  &.disabled,
  &:disabled {
    color: $info;
    background-color: transparent;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $info;
      border-color: $info;
    }
  }
}
.show > .btn-outline-info.dropdown-toggle {
  color: $white !important;
  background-color: $info;
  border-color: $info;
}
.btn-outline-info:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.1);
  }
}
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.1);
}
.btn-outline-warning {
  color: $warning !important;
  background-color: transparent;
  background-image: none;
  border-color: $warning;
  box-shadow: 0 0px 10px -5px rgba(247, 191, 13, 0.5);
  &:hover {
    color: $white !important;
    background-color: $warning;
    border-color: $warning;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
  }
  &.disabled,
  &:disabled {
    color: $warning;
    background-color: transparent;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $warning;
      border-color: $warning;
    }
  }
}
.show > .btn-outline-warning.dropdown-toggle {
  color: $white !important;
  background-color: $warning;
  border-color: $warning;
}
.btn-outline-warning:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
  }
}
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
}
.btn-outline-danger {
  color: #dc0441 !important;
  background-color: transparent;
  background-image: none;
  border-color: #dc0441;
  box-shadow: 0 0px 10px -5px rgba(220, 4, 65, 0.5);
  &:hover {
    color: $white !important;
    background-color: #dc0441;
    border-color: #dc0441;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
  }
  &.disabled,
  &:disabled {
    color: #dc0441;
    background-color: transparent;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #dc0441;
      border-color: #dc0441;
    }
  }
}
.show > .btn-outline-danger.dropdown-toggle {
  color: $white !important;
  background-color: #dc0441;
  border-color: #dc0441;
}
.btn-outline-danger:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
  }
}
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
}
.btn-outline-light {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: #eceef7;
  box-shadow: 0 0px 10px -5px rgb(232, 235, 242);
  &:hover {
    color: #495057;
    background-color: #eceef7;
    border-color: #eceef7;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
  }
  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #495057;
      background-color: #eceef7;
      border-color: #eceef7;
    }
  }
}
.show > .btn-outline-light.dropdown-toggle {
  color: #495057;
  background-color: #eceef7;
  border-color: #eceef7;
}
.btn-outline-light:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
  }
}
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}
.btn-outline-dark {
  color: $dark;
  background-color: transparent;
  background-image: none;
  border-color: $dark !important;
  box-shadow: 0 0px 10px -5px rgba(68, 84, 195, 0.5);
  &:hover {
    color: $white;
    background-color: $dark;
    border-color: $dark;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
  }
  &.disabled,
  &:disabled {
    color: $dark;
    background-color: transparent;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $dark;
      border-color: $dark;
    }
  }
}
.show > .btn-outline-dark.dropdown-toggle {
  color: $white;
  background-color: $dark;
  border-color: $dark;
}
.btn-outline-dark:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
  }
}
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}
.btn-link {
  font-weight: 400;
  color: $primary;
  background-color: transparent;
  text-decoration: none;
  &:hover {
    color: #3c49de;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
  }
  &:focus,
  &.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none;
  }
  &:disabled,
  &.disabled {
    color: #868e96;
    pointer-events: none;
  }
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 2rem;
  font-size: 1.125rem;
  line-height: 1.625;
}
.btn-sm,
.btn-group-sm > .btn {
  font-size: 0.875rem;
  line-height: 1.33333333;
}
.btn-block {
  display: block;
  width: 100%;
  + .btn-block {
    margin-top: 0.5rem;
  }
}
input {
  &[type='submit'].btn-block,
  &[type='reset'].btn-block,
  &[type='button'].btn-block {
    width: 100%;
  }
}
.fade {
  transition: opacity 0.15s linear;
  &:not(.show) {
    opacity: 0;
  }
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.collapse:not(.show) {
  display: none;
  background: $white;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.btn {
  cursor: pointer;
  letter-spacing: 0.03em;
}
.btn-icon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
}
.btn i {
  font-size: 1rem;
  line-height: 1.5;
}
.btn-pill {
  border-radius: 10rem;
}
.btn-square {
  border-radius: 0;
}
.btn-facebook {
  color: $white;
  background-color: #3b5998;
  border-color: #3b5998;
  &:hover {
    color: $white;
    background-color: #30497c;
    border-color: #2d4373;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #3b5998;
    border-color: #3b5998;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #2d4373;
      border-color: #293e6a;
    }
  }
}
.show > .btn-facebook.dropdown-toggle {
  color: $white;
  background-color: #2d4373;
  border-color: #293e6a;
}
.btn-facebook:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
  }
}
.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
}
.btn-twitter {
  color: $white;
  background-color: #1da1f2;
  border-color: #1da1f2;
  &:hover {
    color: $white;
    background-color: #0d8ddc;
    border-color: #0c85d0;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #1da1f2;
    border-color: #1da1f2;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #0c85d0;
      border-color: #0b7ec4;
    }
  }
}
.show > .btn-twitter.dropdown-toggle {
  color: $white;
  background-color: #0c85d0;
  border-color: #0b7ec4;
}
.btn-twitter:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
  }
}
.show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
}
.btn-google {
  color: $white;
  background-color: #dc4e41;
  border-color: #dc4e41;
  &:hover {
    color: $white;
    background-color: #d03526;
    border-color: #c63224;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #dc4e41;
    border-color: #dc4e41;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #c63224;
      border-color: #bb2f22;
    }
  }
}
.show > .btn-google.dropdown-toggle {
  color: $white;
  background-color: #c63224;
  border-color: #bb2f22;
}
.btn-google:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
  }
}
.show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
}
.btn-youtube {
  color: $white;
  background-color: #f00;
  border-color: #f00;
  &:hover {
    color: $white;
    background-color: #d90000;
    border-color: #cc0000;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #f00;
    border-color: #f00;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #cc0000;
      border-color: #bf0000;
    }
  }
}
.show > .btn-youtube.dropdown-toggle {
  color: $white;
  background-color: #cc0000;
  border-color: #bf0000;
}
.btn-youtube:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
  }
}
.show > .btn-youtube.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
}
.btn-vimeo {
  color: $white;
  background-color: #1ab7ea;
  border-color: #1ab7ea;
  &:hover {
    color: $white;
    background-color: #139ecb;
    border-color: #1295bf;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #1ab7ea;
    border-color: #1ab7ea;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #1295bf;
      border-color: #108cb4;
    }
  }
}
.show > .btn-vimeo.dropdown-toggle {
  color: $white;
  background-color: #1295bf;
  border-color: #108cb4;
}
.btn-vimeo:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
  }
}
.show > .btn-vimeo.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
}
.btn-dribbble {
  color: $white;
  background-color: #ea4c89;
  border-color: #ea4c89;
  &:hover {
    color: $white;
    background-color: #e62a72;
    border-color: #e51e6b;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #ea4c89;
    border-color: #ea4c89;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #e51e6b;
      border-color: #dc1a65;
    }
  }
}
.show > .btn-dribbble.dropdown-toggle {
  color: $white;
  background-color: #e51e6b;
  border-color: #dc1a65;
}
.btn-dribbble:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
  }
}
.show > .btn-dribbble.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
}
.btn-github {
  color: $white;
  background-color: #181717;
  border-color: #181717;
  &:hover {
    color: $white;
    background-color: #040404;
    border-color: black;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #181717;
    border-color: #181717;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: black;
      border-color: black;
    }
  }
}
.show > .btn-github.dropdown-toggle {
  color: $white;
  background-color: black;
  border-color: black;
}
.btn-github:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
  }
}
.show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
}
.btn-instagram {
  color: $white;
  background-color: #e4405f;
  border-color: #e4405f;
  &:hover {
    color: $white;
    background-color: #de1f44;
    border-color: #d31e40;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #e4405f;
    border-color: #e4405f;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #d31e40;
      border-color: #c81c3d;
    }
  }
}
.show > .btn-instagram.dropdown-toggle {
  color: $white;
  background-color: #d31e40;
  border-color: #c81c3d;
}
.btn-instagram:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
  }
}
.show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
}
.btn-pinterest {
  color: $white;
  background-color: #bd081c;
  border-color: #bd081c;
  &:hover {
    color: $white;
    background-color: #980617;
    border-color: #8c0615;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #bd081c;
    border-color: #bd081c;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #8c0615;
      border-color: #800513;
    }
  }
}
.show > .btn-pinterest.dropdown-toggle {
  color: $white;
  background-color: #8c0615;
  border-color: #800513;
}
.btn-pinterest:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
  }
}
.show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
}
.btn-vk {
  color: $white;
  background-color: #6383a8;
  border-color: #6383a8;
  &:hover {
    color: $white;
    background-color: #527093;
    border-color: #4d6a8b;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #6383a8;
    border-color: #6383a8;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #4d6a8b;
      border-color: #496482;
    }
  }
}
.show > .btn-vk.dropdown-toggle {
  color: $white;
  background-color: #4d6a8b;
  border-color: #496482;
}
.btn-vk:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
  }
}
.show > .btn-vk.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
}
.btn-rss {
  color: $white;
  background-color: #ffa500;
  border-color: #ffa500;
  &:hover {
    color: $white;
    background-color: #d98c00;
    border-color: #cc8400;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #ffa500;
    border-color: #ffa500;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #cc8400;
      border-color: #bf7c00;
    }
  }
}
.show > .btn-rss.dropdown-toggle {
  color: $white;
  background-color: #cc8400;
  border-color: #bf7c00;
}
.btn-rss:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
  }
}
.show > .btn-rss.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
}
.btn-flickr {
  color: $white;
  background-color: #0063dc;
  border-color: #0063dc;
  &:hover {
    color: $white;
    background-color: #0052b6;
    border-color: #004ca9;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #0063dc;
    border-color: #0063dc;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #004ca9;
      border-color: #00469c;
    }
  }
}
.show > .btn-flickr.dropdown-toggle {
  color: $white;
  background-color: #004ca9;
  border-color: #00469c;
}
.btn-flickr:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
  }
}
.show > .btn-flickr.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
}
.btn-blue {
  color: $white;
  background-color: $blue;
  border-color: $blue;
  &:hover {
    color: $white !important;
    background-color: #1747bd;
    border-color: #1747bd;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #1747bd;
    border-color: #1747bd;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #1747bd;
      border-color: #1747bd;
    }
  }
}
.show > .btn-blue.dropdown-toggle {
  color: $white;
  background-color: #1747bd;
  border-color: #1747bd;
}
.btn-blue:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
  }
}
.show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
}
.btn-indigo {
  color: $white;
  background-color: $indigo;
  border-color: $indigo;
  &:hover {
    color: $white !important;
    background-color: #485ac4;
    border-color: #3f51c1;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $indigo;
    border-color: $indigo;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #3f51c1;
      border-color: #3b4db7;
    }
  }
}
.show > .btn-indigo.dropdown-toggle {
  color: $white;
  background-color: #3f51c1;
  border-color: #3b4db7;
}
.btn-indigo:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
  }
}
.show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
}
.btn-purple {
  color: $white;
  background-color: $purple;
  border-color: $purple;
  &:hover {
    color: $white !important;
    background-color: #923ce6;
    border-color: #8c31e4;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $purple;
    border-color: $purple;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #8c31e4;
      border-color: #8526e3;
    }
  }
}
.show > .btn-purple.dropdown-toggle {
  color: $white;
  background-color: #8c31e4;
  border-color: #8526e3;
}
.btn-purple:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
  }
}
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
}
.btn-pink {
  color: $white;
  background-color: $pink;
  border-color: $pink;
  &:hover {
    color: $white !important;
    background-color: #f44982;
    border-color: #f33d7a;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $pink;
    border-color: $pink;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #f33d7a;
      border-color: #f23172;
    }
  }
}
.show > .btn-pink.dropdown-toggle {
  color: $white;
  background-color: #f33d7a;
  border-color: #f23172;
}
.btn-pink:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
  }
}
.show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
}
.btn-red {
  color: $white;
  background-color: #dc0441;
  border-color: #dc0441;
  &:hover {
    color: $white;
    background-color: #ac1b1a;
    border-color: #a11918;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #dc0441;
    border-color: #dc0441;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #a11918;
      border-color: #961717;
    }
  }
}
.show > .btn-red.dropdown-toggle {
  color: $white;
  background-color: #a11918;
  border-color: #961717;
}
.btn-red:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
  }
}
.show > .btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
}
.btn-orange {
  color: $white;
  background-color: $orange;
  border-color: $orange;
  &:hover {
    color: $white;
    background-color: #fd811e;
    border-color: #fc7a12;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $orange;
    border-color: $orange;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #fc7a12;
      border-color: #fc7305;
    }
  }
}
.show > .btn-orange.dropdown-toggle {
  color: $white;
  background-color: #fc7a12;
  border-color: #fc7305;
}
.btn-orange:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
  }
}
.show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
}
.btn-yellow {
  color: $white;
  background-color: $warning;
  border-color: $warning;
  &:hover {
    color: $white;
    background-color: #cea70c;
    border-color: #c29d0b;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $warning;
    border-color: $warning;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #c29d0b;
      border-color: #b6940b;
    }
  }
}
.show > .btn-yellow.dropdown-toggle {
  color: $white;
  background-color: #c29d0b;
  border-color: #b6940b;
}
.btn-yellow:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
  }
}
.show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
}
.btn-green {
  color: $white;
  background-color: $success;
  border-color: $success;
  &:hover {
    color: $white;
    background-color: #15ca7c;
    border-color: #15ca7c;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(9, 191, 137, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $success;
    border-color: $success;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #15ca7c;
      border-color: #3e7a00;
    }
  }
}
.show > .btn-green.dropdown-toggle {
  color: $white;
  background-color: #15ca7c;
  border-color: #3e7a00;
}
.btn-green:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(9, 191, 137, 0.5);
  }
}
.show > .btn-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(9, 191, 137, 0.5);
}
.btn-teal {
  color: $white;
  background-color: $teal;
  border-color: $teal;
  &:hover {
    color: $white;
    background-color: #24ab9d;
    border-color: #22a193;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $teal;
    border-color: $teal;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #22a193;
      border-color: #20968a;
    }
  }
}
.show > .btn-teal.dropdown-toggle {
  color: $white;
  background-color: #22a193;
  border-color: #20968a;
}
.btn-teal:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
  }
}
.show > .btn-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
}
.btn-cyan {
  color: $white;
  background-color: #17a2b8;
  border-color: #17a2b8;
  &:hover {
    color: $white;
    background-color: #138496;
    border-color: #117a8b;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #117a8b;
      border-color: #10707f;
    }
  }
}
.show > .btn-cyan.dropdown-toggle {
  color: $white;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-cyan:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
  }
}
.show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
}
.btn-white {
  background-color: $white;
  background-image: none;
  border: $border;
  color: $color;
  &:hover {
    background-color: $white;
    background-image: none;
    border: $border;
  }
  &:focus,
  &.focus {
    box-shadow: none;
  }
  &.disabled,
  &:disabled {
    color: #495057;
    background-color: $white;
    border: $white;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #495057;
      background-color: $white;
      border: $border;
    }
  }
}
.show > .btn-white.dropdown-toggle {
  color: #495057;
  background-color: #e9ebfa;
  border-color: #e7e9f1;
}
.btn-white:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px $white-5;
  }
}
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px $white-5;
}
.btn-gray {
  color: $white;
  background-color: #868e96;
  border-color: #868e96;
  &:hover {
    color: $white;
    background-color: #727b84;
    border-color: #6c757d;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #868e96;
    border-color: #868e96;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #6c757d;
      border-color: #666e76;
    }
  }
}
.show > .btn-gray.dropdown-toggle {
  color: $white;
  background-color: #6c757d;
  border-color: #666e76;
}
.btn-gray:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
  }
}
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
}
.btn-gray-dark {
  color: $white;
  background-color: $dark;
  border-color: $dark;
  &:hover {
    color: $white;
    background-color: #23272b;
    border-color: #1d2124;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $dark;
    border-color: $dark;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #1d2124;
      border-color: #171a1d;
    }
  }
}
.show > .btn-gray-dark.dropdown-toggle {
  color: $white;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-gray-dark:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
  }
}
.show > .btn-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
}
.btn-azure {
  color: $white;
  background-color: $info;
  border-color: $info;
  &:hover {
    color: $white !important;
    background-color: #219af0;
    border-color: #1594ef;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $info;
    border-color: $info;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #1594ef;
      border-color: #108ee7;
    }
  }
}
.show > .btn-azure.dropdown-toggle {
  color: $white;
  background-color: #1594ef;
  border-color: #108ee7;
}
.btn-azure:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5);
  }
}
.show > .btn-azure.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5);
}
.btn-lime {
  color: $white;
  background-color: #7bd235;
  border-color: #7bd235;
  &:hover {
    color: $white;
    background-color: #69b829;
    border-color: #63ad27;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: #7bd235;
    border-color: #7bd235;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: #63ad27;
      border-color: #5da324;
    }
  }
}
.show > .btn-lime.dropdown-toggle {
  color: $white;
  background-color: #63ad27;
  border-color: #5da324;
}
.btn-lime:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
  }
}
.show > .btn-lime.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
}
.btn-sm,
.btn-group-sm > .btn {
  font-size: 0.75rem;
  min-width: 1.625rem;
}
.btn-lg,
.btn-group-lg > .btn {
  font-size: 16px;
  min-width: 2.75rem;
}
.btn-list {
  margin-bottom: -0.5rem;
  font-size: 0;
  > {
    .btn,
    .dropdown {
      margin-bottom: 0.5rem;
    }
    .btn:not(:last-child),
    .dropdown:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
.btn-loading {
  color: transparent !important;
  pointer-events: none;
  position: relative;
  &:after {
    content: '';
    -webkit-animation: loader 500ms infinite linear;
    animation: loader 500ms infinite linear;
    border: 2px solid $white;
    border-radius: 50%;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    display: block;
    height: 1.4em;
    width: 1.4em;
    position: absolute;
    left: calc(50% - (1.4em / 2));
    top: calc(50% - (1.4em / 2));
    -webkit-transform-origin: center;
    transform-origin: center;
    position: absolute !important;
  }
  &.btn-sm:after {
    height: 1em;
    width: 1em;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
  }
}
.btn-group-sm > .btn-loading.btn:after {
  height: 1em;
  width: 1em;
  left: calc(50% - (1em / 2));
  top: calc(50% - (1em / 2));
}
.btn-loading.btn-secondary:after {
  border-color: #495057;
}
.btn-icon.btn-svg {
  .svg-icon {
    width: 16px;
    margin-top: 2px;
  }
  line-height: 15px;
}
.btn-svgs {
  .svg-icon {
    margin-right: 6px;
  }
  display: inline-flex;
}
.btn-svg-text {
  margin-top: 2px;
}
.btn-primary .svg-icon,
.btn-secondary .svg-icon,
.btn-success .svg-icon,
.btn-info .svg-icon,
.btn-warning .svg-icon,
.btn-danger .svg-icon,
.btn-dark .svg-icon {
  fill: $white-6;
}
.data-table-btn {
  position: absolute;
  left: 200px;
  z-index: 1;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn {
  &:focus,
  &:active,
  &.active {
    z-index: 1;
  }
}
.btn-group-vertical > .btn {
  &:focus,
  &:active,
  &.active {
    z-index: 1;
  }
}
.btn-group {
  .btn + {
    .btn,
    .btn-group {
      margin-left: -1px;
      border-left: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
  .btn-group + {
    .btn,
    .btn-group {
      margin-left: -1px;
    }
  }
}
.btn-group-vertical {
  .btn + {
    .btn,
    .btn-group {
      margin-left: -1px;
    }
  }
  .btn-group + {
    .btn,
    .btn-group {
      margin-left: -1px;
    }
  }
}
.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  .input-group {
    width: auto;
  }
}
.btn-group > {
  .btn {
    &:first-child {
      margin-left: 0;
    }
    &:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  //.btn:not(:first-child), .btn-group:not(:first-child)>.btn {
  //	border-top-left-radius: 0;
  //	border-bottom-left-radius: 0;
  //}
}
.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
  .btn,
  .btn-group {
    width: 100%;
  }
  > {
    .btn + {
      .btn,
      .btn-group {
        margin-top: -1px;
        margin-left: 0;
      }
    }
    .btn-group + {
      .btn,
      .btn-group {
        margin-top: -1px;
        margin-left: 0;
      }
    }
    .btn:not(:last-child):not(.dropdown-toggle),
    .btn-group:not(:last-child) > .btn {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .btn:not(:first-child),
    .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
.btn-group-toggle > {
  .btn,
  .btn-group > .btn {
    margin-bottom: 0;
  }
  .btn input {
    &[type='radio'],
    &[type='checkbox'] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }
  .btn-group > .btn input {
    &[type='radio'],
    &[type='checkbox'] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }
}
.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #495057;
  background-color: #eceef7;
  border-color: #eceef7;
}
.rounded-30 {
  border-radius: 30px !important;
}
.w-xs {
  min-width: 90px;
}

.w-sm {
  min-width: 110px;
}

.w-md {
  min-width: 130px;
}

.w-lg {
  min-width: 150px;
}

.btn-primary-transparent {
  background: $primary-02;
  color: $primary;
  &:hover {
    color: $white !important;
    background-color: $primary;
    border-color: $primary;
    opacity: 0.9;
  }
  &:focus,
  &.focus {
    color: $white !important;
    background-color: $primary;
    border-color: $primary;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $primary-hover;
    border-color: $primary-hover;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $primary-hover;
      border-color: $primary-hover;
    }
  }
}
.btn-warning-transparent {
  background: rgba(251, 197, 24, 0.1);
  color: $warning;
  &:hover {
    color: $white !important;
    background-color: $warning;
    border-color: $warning;
    opacity: 0.9;
  }
  &:focus,
  &.focus {
    color: $white !important;
    background-color: $warning;
    border-color: $warning;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $warning;
    border-color: $warning;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $warning;
      border-color: $warning;
    }
  }
}
.btn-danger-transparent {
  background: rgba(247, 40, 74, 0.1);
  color: $danger;
  &:hover {
    color: $white !important;
    background-color: $danger;
    border-color: $danger;
    opacity: 0.9;
  }
  &:focus,
  &.focus {
    color: $white !important;
    background-color: $danger;
    border-color: $danger;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $danger;
    border-color: $danger;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $danger;
      border-color: $danger;
    }
  }
}
.btn-pink-transparent {
  background: rgba(239, 78, 184, 0.1);
  color: $pink;
  &:hover {
    color: $white !important;
    background-color: $pink;
    border-color: $pink;
    opacity: 0.9;
  }
  &:focus,
  &.focus {
    color: $white !important;
    background-color: $pink;
    border-color: $pink;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $pink;
    border-color: $pink;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $pink;
      border-color: $pink;
    }
  }
}
.btn-success-transparent {
  background: rgba(13, 205, 148, 0.1);
  color: $success;
  &:hover {
    color: $white !important;
    background-color: $success;
    border-color: $success;
    opacity: 0.9;
  }
  &:focus,
  &.focus {
    color: $white !important;
    background-color: $success;
    border-color: $success;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $success;
    border-color: $success;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $success;
      border-color: $success;
    }
  }
}
.btn-info-transparent {
  background: rgba(18, 138, 249, 0.1);
  color: $info;
  &:hover {
    color: $white !important;
    background-color: $info;
    border-color: $info;
    opacity: 0.9;
  }
  &:focus,
  &.focus {
    color: $white !important;
    background-color: $info;
    border-color: $info;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $info;
    border-color: $info;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $info;
      border-color: $info;
    }
  }
}
.btn-orange-transparent {
  background: rgba(243, 73, 50, 0.1);
  color: $orange;
  &:hover {
    color: $white !important;
    background-color: $orange;
    border-color: $orange;
    opacity: 0.9;
  }
  &:focus,
  &.focus {
    color: $white !important;
    background-color: $orange;
    border-color: $orange;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $orange;
    border-color: $orange;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $orange;
      border-color: $orange;
    }
  }
}
.btn-secondary-transparent {
  background: rgba(254, 127, 0, 0.1);
  color: $secondary;
  &:hover {
    color: $white !important;
    background-color: $secondary;
    border-color: $secondary;
    opacity: 0.9;
  }
  &:focus,
  &.focus {
    color: $white !important;
    background-color: $secondary;
    border-color: $secondary;
  }
  &.disabled,
  &:disabled {
    color: $white;
    background-color: $secondary;
    border-color: $secondary;
  }
  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $white;
      background-color: $secondary;
      border-color: $secondary;
    }
  }
}
@media (max-width: 767px) {
  .radiobtns [class*='btn-outline-'],
  .checkboxbtns [class*='btn-outline-'] {
    padding: 0.3rem 0.35rem;
    line-height: 2;
    font-size: 12px;
  }
}
