@import '~bootstrap/scss/bootstrap';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~ngx-toastr/toastr-bs4-alert';

@import './components.scss';
@import './animations.scss';
@import './scss/style.scss';

@font-face {
  font-family: 'Inter';
  src: url('../assets/font/Inter/Inter-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/font/Inter/Inter-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/font/Inter/Inter-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/font/Inter/Inter-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: 'Inter', sans-serif;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  color: #464c54;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Inter', sans-serif;
}

.btn {
  color: #464c54;
}

.wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

ngx-spinner div {
  &.loading-text,
  div.la-ball-pulse .loading-text {
    font-size: 2rem;
    color: #fff;
  }
}

a.link {
  text-decoration: underline !important;
  cursor: pointer !important;

  &:hover {
    text-decoration: none !important;
  }
}

.link-arrow {
  cursor: pointer !important;
}

.text-danger {
  color: #721c24 !important;
}

// .form-control {
//   height: auto;
//   font-size: 1.3em;
// }

.main-wrapper > router-outlet + * {
  width: 100%;
}

ngb-alert p {
  margin-bottom: 0;
}

.text-decoration-underline {
  text-decoration: underline;
}

@media (min-width: 575.98px) {
  .app-content .side-app {
    padding: 25px 1rem 0;
  }
}

@media print {
  @page {
    margin: 0;
  }

  body {
    background-color: $white;
  }

  .app-content .side-app {
    padding: 0;
  }
}
